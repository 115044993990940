<template>
  <div>
    <van-nav-bar title="账号登录" left-arrow @click-left="$router.go(-1)" />
    <div class="d-flex row-center margin-b-40"></div>
    <van-form>
      <van-field
        v-model="account"
        name="用户名"
        label="用户名"
        placeholder="用户名"
      />
      <van-field
        v-model="password"
        type="password"
        name="密码"
        label="密码"
        placeholder="密码"
      />
      <div style="margin: 16px">
        <van-button block type="info" @click="onSubmit"> 登录 </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { login } from "@/apis/auth.js";
import storage from "@/utils/storage";
export default {
  data() {
    return {
      account: "",
      password: "",
    };
  },
  created() {},
  methods: {
    onSubmit() {
      let { account, password } = this;
      if (!this.account || !password) {
        this.$toast("账号密码不能为空！");
        return;
      }
      login({
        account,
        password,
      }).then((res) => {
        if (res.succeeded) {
          storage.set("loginUserInfo", res.data);
          storage.remove("listParams");
          this.$router.replace({
            name: "list",
          });
        }
      });
    },
  },
};
</script>
